<script setup>
const props = defineProps({
    isFromRegister: Boolean
})
console.log(props.isFromRegister);

const emits = defineEmits(['closeModal'])

const closeModal = () => {
    emits('closeModal')
}
</script>

<template>

    <body>
        <button class="hide-button" @click="closeModal">&times;</button>
        <div class="header">
            <img src="/LogoLettersDesktop.png" alt="logo de WeAreTesters">
            <div>
                <h2 style="text-align:center;">{{ $t('terms_condition_title') }}</h2>
            </div>
        </div>
        <main>
            <ol>
                <strong>
                    <li>{{ $t('terms_condition_subtitle') }}</li>
                </strong>
            </ol>

            <p>{{ $t('terms_condition_info1') }}</p>

            <p>{{ $t('terms_condition_info2') }}</p>

            <p>{{ $t('terms_condition_info3') }}</p>

            <ol start="2">
                <strong>
                    <li><span>{{ $t('terms_condition_list_object') }}</span></li>
                </strong>
            </ol>

            <p><strong><span> </span></strong></p>

            <p>{{ $t('terms_condition_list_info') }}</p>

            <ul>
                <li>{{ $t('terms_condition_list_subinfo1') }}</li>
                <li>{{ $t('terms_condition_list_subinfo1,1') }}</li>
                <li>{{ $t('terms_condition_list_subinfo1,2') }}</li>
                <li>{{ $t('terms_condition_list_subinfo1,3') }}</li>
            </ul>

            <p>{{ $t('terms_condition_list_subinfo2') }}</p>

            <p><span>{{ $t('terms_condition_list_subinfo3') }}</span></p>

            <p><span> </span></p>

            <p><span>{{ $t('terms_condition_list_subinfo4') }}</span></p>

            <ol start="3">
                <strong>
                    <li style="margin-right: 6px; "><span style="font-size:12pt"><span
                                style="font-family:Calibri,sans-serif">
                                <span style="color:black"></span></span></span><span>{{
            $t('terms_condition_list_subtitle') }}</span></li>
                </strong>
            </ol>

            <p style="margin-bottom: 13px; "><span>{{ $t('terms_condition_access_platform') }}</span></p>

            <p style="margin-bottom: 13px; "><span>{{ $t('terms_condition_navigation_free') }}</span></p>

            <p style="margin-bottom: 13px; "><span>{{ $t('terms_condition_registration_requirement') }}</span>
            </p>

            <p style="margin-bottom: 13px; "><span>{{ $t('terms_condition_password_management') }}</span></p>

            <p style="margin-bottom: 13px; "><span>{{ $t('terms_condition_participant_responsibility') }}</span><span>
                </span></p>

            <ol start="4">
                <strong>
                    <li style="margin-right: 6px; "><span>{{ $t('terms_condition_panelist_participant_condition')
                            }}</span>
                    </li>
                </strong>
            </ol>

            <p><span>{{ $t('terms_condition_panelist_definition') }}</span></p>

            <p><strong><em><span>{{ $t('terms_condition_profile_configuration') }}</span></em></strong></p>

            <p>{{ $t('terms_condition_profile_modify') }}</p>

            <p>{{ $t('terms_condition_user_alias') }}</p>

            <p><span>{{ $t('terms_condition_notifications') }} </span></p>

            <p><strong><em>{{ $t('terms_condition_startup') }}</em></strong></p>

            <p><span>{{ $t('terms_condition_active_tests') }} </span></p>


            <p>{{ $t('terms_condition_test_availability') }}</p>

            <p><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"> <span
                            style="color:black"></span></span></span></p>

            <ol start="5">
                <strong>
                    <li>{{ $t('terms_condition_rights_obligations') }}</li>
                </strong>
            </ol>

            <p>{{ $t('terms_condition_responsible_use') }}</p>
            <p>{{ $t('terms_condition_access_credentials') }}</p>
            <p>{{ $t('terms_condition_data_accuracy') }}</p>
            <p>{{ $t('terms_condition_legal_compliance') }}</p>

            <ol start="6">
                <strong>
                    <li>{{ $t('terms_condition_community_rights_obligations') }}</li>
                </strong>
            </ol>

            <p>{{ $t('terms_condition_participant_requirements') }}</p>

            <ul>
                <li>{{ $t('terms_condition_activity_completion') }}</li>
            </ul>

            <ul>
                <li>{{ $t('terms_condition_activity_type') }}</li>
            </ul>

            <p>{{ $t('terms_condition_moderator_decision') }}</p>

            <ol start="7">
                <strong>
                    <li>{{ $t('terms_condition_points_incentive_program') }}</li>
                </strong>
            </ol>

            <p>{{ $t('terms_condition_rewards_description') }}
            </p>

            <ul>
                <li>{{ $t('terms_condition_rewards_watios') }}</li>
                <li>{{ $t('terms_condition_rewards_watcoins') }}</li>
            </ul>

            <p>{{ $t('terms_condition_rewards_activities') }}</p>

            <ul>
                <li>{{ $t('terms_condition_rewards_invite_friend') }}</li>
                <li>{{ $t('terms_condition_rewards_level_up') }}</li>
                <li>{{ $t('terms_condition_rewards_test_response') }}</li>
            </ul>

            <ol start="8">
                <strong>
                    <li>{{ $t('terms_condition_panelist_status') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_panelist_statuses_description') }}</p>

            <ul>
                <li>
                    {{ $t('terms_condition_panelist_status_padawat') }}
                </li>

                <li>
                    {{ $t('terms_condition_panelist_status_watpro') }}

                </li>

                <li>
                    {{ $t('terms_condition_panelist_status_watguru') }}

                </li>
            </ul>

            <p>
                {{ $t('terms_condition_panelist_privileges_description') }}

            </p>

            <table class="Table" style="border-collapse:collapse">
                <thead>
                    <tr>
                        <td></td>
                        <td>
                            <p>PádaWat</p>
                        </td>
                        <td>
                            <p>WATpro</p>
                        </td>
                        <td>
                            <p>WATguru</p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <p> {{ $t('terms_condition_panelist_statuses_levels_title') }}
                            </p>
                        </td>
                        <td>
                            <p> {{ $t('terms_condition_panelist_status_table_padawat.level_range') }}
                            </p>
                        </td>
                        <td>
                            <p>{{ $t('terms_condition_panelist_status_table_watpro.level_range') }}</p>
                        </td>
                        <td>
                            <p>{{ $t('terms_condition_panelist_status_table_watguru.level_range') }}</p>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ $t('terms_condition_panelist_statuses_bonuses_title') }}</p>

                        </td>
                        <td>
                            <p>-</p>
                        </td>
                        <td>
                            <p>+5%</p>
                        </td>
                        <td>
                            <p>+10%</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ $t('terms_condition_panelist_statuses_priority_title') }}</p>

                        </td>
                        <td>
                            <p>{{ $t('terms_condition_panelist_status_table_padawat.priority') }}</p>

                        </td>
                        <td>
                            <p>{{ $t('terms_condition_panelist_status_table_watpro.priority') }}</p>


                        </td>
                        <td>
                            <p>{{ $t('terms_condition_panelist_status_table_watguru.priority') }}</p>


                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ $t('terms_condition_panelist_statuses_invitations_title') }}</p>

                        </td>
                        <td>
                            <p>10</p>
                        </td>
                        <td>
                            <p>20</p>
                        </td>
                        <td>
                            <p>30</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ $t('terms_condition_panelist_statuses_exclusive_access_title') }}</p>

                        </td>
                        <td>
                            <p>{{ $t('terms_condition_panelist_status_table_padawat.exclusive_access') }}</p>

                        </td>
                        <td>
                            <p>{{ $t('terms_condition_panelist_status_table_watpro.exclusive_access') }}</p>


                        </td>
                        <td>
                            <p>{{ $t('terms_condition_panelist_status_table_watguru.exclusive_access') }}</p>


                        </td>
                    </tr>
                </tbody>
            </table>

            <ol start="9">
                <strong>
                    <li>{{ $t('terms_condition_panelist_ranking_points_title') }}</li>
                </strong>
            </ol>

            <p>{{ $t('terms_condition_panelist_account_ranking_description') }}</p>
            <p>{{ $t('terms_condition_panelist_account_history_description') }}</p>


            <ol start="10">
                <strong>
                    <li>{{ $t('terms_condition_panelist_prohibitions_title') }}</li>
                </strong>
            </ol>

            <p>{{ $t('terms_condition_panelist_restrictions_description') }}</p>

            <ul>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph1') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph2') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph3') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph4') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph5') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph6') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph7') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph8') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph9') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph10') }}</li>
                <li>{{ $t('terms_condition_panelist_restrictions_paragraph11') }}</li>

            </ul>

            <p><strong>{{ $t('terms_condition_platform_functionalities_paragraph') }}</strong>.
                {{ $t('terms_condition_platform_functionalities_paragraph2') }}</p>

            <ul>
                <li>{{ $t('terms_condition_comments_list_item1') }}</li>
                <li>{{ $t('terms_condition_comments_list_item2') }}</li>
                <li>{{ $t('terms_condition_comments_list_item3') }}</li>
                <li>{{ $t('terms_condition_comments_list_item4') }}</li>

            </ul>

            <p>{{ $t('terms_condition_comments_control') }}</p>

            <ol start="11">
                <strong>
                    <li>{{ $t('terms_condition_activity_title') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_activity_info') }}</p>


            <p>{{ $t('terms_condition_activity_recording') }}</p>


            <p>{{ $t('terms_condition_activity_consent') }}</p>


            <p>{{ $t('terms_condition_participation_voluntary') }}</p>


            <ol start="12">
                <strong>
                    <li>{{ $t('terms_condition_personal_data_title') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_personal_data_info') }}</p>


            <p><strong>{{ $t('terms_condition_personal_data_responsible') }}</strong></p>

            <ul>
                <li>{{ $t('terms_condition_company_name') }}</li>
                <li>{{ $t('terms_condition_company_nif') }}</li>
                <li>{{ $t('terms_condition_company_address') }}</li>
                <li>{{ $t('terms_condition_company_email', { email: email } ) }}</li>

            </ul>

            <p><strong>{{ $t('terms_condition_data_protection_delegate_email', { email: email }) }}</strong></p>

            <p><strong>{{ $t('terms_condition_data_protection_delegate_email_treatment') }}</strong></p>


            <ul>
                <li>{{ $t('terms_condition_processing_purpose_1') }}</li>
                <li>{{ $t('terms_condition_processing_purpose_2') }}</li>
                <li>{{ $t('terms_condition_processing_purpose_3') }}</li>

            </ul>

            <p><strong>{{ $t('terms_condition_legitimacy') }}</strong></p>


            <p>{{ $t('terms_condition_tratamiento_datos') }}</p>

            <p><strong>{{ $t('terms_condition_tratamiento_datos_criteria') }}</strong></p>

            <p>{{ $t('terms_condition_criteria_conservation') }}</p>


            <p>{{ $t('terms_condition_activity_conservation') }}</p>


            <p><strong>{{ $t('terms_condition_activity_communication') }}</strong></p>


            <p>{{ $t('terms_condition_no_anonimous') }}</p>


            <p>{{ $t('terms_condition_no_third_communication') }}</p>


            <p><strong>{{ $t('terms_condition_tranferences_title') }}</strong></p>


            <p>{{ $t('terms_condition_internation_transferences') }}</p>


            <p><strong>{{ $t('terms_condition_rigths_interested') }}</strong></p>


            <p>{{ $t('terms_condition_revoke_consent') }}</p>
            <p>{{ $t('terms_condition_exercise_rights', { email: email }) }}</p>
            <p>{{ $t('terms_condition_contact_dpo', { email: email }) }}</p>


            <ol start="13">
                <strong>
                    <li>{{ $t('terms_condition_confidentiality') }}</li>
                </strong>
            </ol>

            <p>{{ $t('terms_condition_participant_commitment') }}</p>


            <ol start="14">
                <strong>
                    <li>{{ $t('terms_condition_intellectual_property') }}</li>

                </strong>
            </ol>

            <p style="margin-bottom: 13px; "><span>{{ $t('terms_condition_company_rights') }}
                </span></p>

            <p style="margin-bottom: 13px; "><span>{{ $t('terms_condition_participant_rights') }}</span><span
                    style="margin-bottom:13px;"><span></span></span></p>

            <p style="margin-bottom: 13px; "><span style="margin-bottom:13px;"><span>{{ $t('terms_condition_prohibited_actions') }}</span></span></p>

            <p><span style="margin-bottom:13px;"><span>{{ $t('terms_condition_user_responsibility') }}</span></span></p>

            <p><span style="margin-bottom:13px;"><span>{{ $t('terms_condition_user_content_license') }}</span></span></p>


            <ol start="15">
                <strong>
                    <li>{{ $t('terms_condition_noncompliance') }}</li>
                </strong>
            </ol>

            <p>{{ $t('terms_condition_participant_noncompliance') }}</p>

            <ol start="16">
                <strong>
                    <li>{{ $t('terms_condition_exclusion_of_warranties') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_platform_functionality') }}</p>
            <p>{{ $t('terms_condition_company_responsibility') }}</p>


            <ul>
                <li>{{ $t('terms_condition_participant_use') }}</li>
                <li>{{ $t('terms_condition_participant_action') }}</li>
                <li>{{ $t('terms_condition_security_errors') }}</li>
                <li>{{ $t('terms_condition_user_content') }}</li>
                <li>{{ $t('terms_condition_service_interruptions') }}</li>

            </ul>

            <p>{{ $t('terms_condition_company_exclusion_of_liability') }}</p>
            <p>{{ $t('terms_condition_platform_access') }}</p>
            <p>{{ $t('terms_condition_information_reliability') }}</p>


            <ol start="17">
                <strong>
                    <li>{{ $t('terms_condition_outbound_links_third_party_services') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_linked_sites_disclaimer') }}</p>
            <p>{{ $t('terms_condition_participant_actions_linked_sites') }}</p>


            <ol start="18">
                <strong>
                    <li>{{ $t('terms_condition_noncompliance_and_indemnity_title') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_monitoring_access') }}</p>
            <p>{{ $t('terms_condition_company_right_suspend') }}</p>
            <p>{{ $t('terms_condition_participant_indemnity') }}</p>


            <ol start="19">
                <strong>
                    <li>{{ $t('terms_condition_service_cancellation_title') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_panelist_cancellation_1') }}</p>
            <p>{{ $t('terms_condition_panelist_cancellation_2') }}</p>


            <ol start="20">
                <strong>
                    <li>{{ $t('terms_condition_communications_title') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_communications_1') }}</p>
            <p>{{ $t('terms_condition_communications_2') }}</p>


            <ol start="21">
                <strong>
                    <li>{{ $t('terms_condition_modifications_title') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_modifications_1') }}</p>
            <p>{{ $t('terms_condition_modifications_2') }}</p>


            <ol start="22">
                <strong>
                    <li>{{ $t('terms_condition_legislation_and_jurisdiction_title') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_legislation_and_jurisdiction_1') }}</p>
            <p>{{ $t('terms_condition_legislation_and_jurisdiction_2') }}</p>
            <p>{{ $t('terms_condition_legislation_and_jurisdiction_3') }}</p>

            <ol start="23">
                <strong>
                    <li>{{ $t('terms_condition_various_title') }}</li>

                </strong>
            </ol>

            <p>{{ $t('terms_condition_various_1') }}</p>

        </main>

    </body>
</template>

<script>
export default {
    data(){
      return{
        email: 'protecciondedatos@wearetesters.com'
      }
    }
}
</script>

<style lang="scss" scoped>
        @import url('https://fonts.cdnfonts.com/css/bariol-regular-2?styles=29982');
        @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    position: relative;
    font-family: "Lato", sans-serif;
    font-size: 0.9em;

    overflow: auto;

}

.header {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
    --tw-gradient-from: #3e216c;
    --tw-gradient-stops: #3e216c, var(--tw-gradient-to, rgba(62, 33, 108, 0));
    --tw-gradient-to: #895ad2;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    position: relative;
}

.hide-button {
    position: absolute;
    top: 5px;
    right: -20px;
    margin: 0;
    z-index: 10000;
    background-color: red;
    color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    transform: translate(-75%, -15%);
}

.hide-button:hover {
    background-color: var(--ion-color-primary-shade);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hide-button:active {
    background-color: var(--ion-color-primary-shade);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.radio-inputs {
    position: relative;
    margin: 20px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: #EEE;
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 100%;
    max-width: 700px;
    font-size: 14px;
}

.radio-inputs .radio {
    flex: 1 1 auto;
    text-align: center;
}

.radio-inputs .radio input {
    display: none;
}

.radio-inputs .radio .name {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    padding: .5rem 0;
    color: rgba(51, 65, 85, 1);
    transition: all .15s ease-in-out;
}

.radio-inputs .radio input:checked+.name {
    background-color: #fff;
    font-weight: 600;
}

img {
    margin: 10px 0;
    padding-bottom: 20px;

}

h2 {
    font-size: x-large;
}

main {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    padding: 0 10%;
}

p {
    margin-bottom: .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

ol {
    font-size: large;
    padding: 10px 0px 10px 00px;
    font-family: "Bariol", sans-serif;
}

li {
    padding: 10px 0;
}

table {
    border-collapse: collapse;
    width: 100%;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(200, 200, 200, var(--tw-border-opacity));
}

tr:first-child {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 52, 255, var(--tw-bg-opacity));
    font-size: .875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

tr:not(:first-child) td {
    padding: .5rem;
    font-size: .75rem;
    line-height: 1rem;
    --tw-text-opacity: 1;
    color: rgba(81, 81, 81, var(--tw-text-opacity));
}

tr:nth-child(2n) {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 242, 255, var(--tw-bg-opacity));
}

th,
td {
    border: 1px solid #bbb;
    padding: 5px;
}

/* Media query para dispositivos de hasta 600px de ancho */
@media screen and (max-width: 600px) {

    .header {
        font-size: smaller;
        padding: 10px;
    }

    h1 {
        font-size: larger;
    }

    h2 {
        font-size: larger;
    }

    main {
        padding: 0 10%;
    }

    p {
        font-size: smaller;
    }

    ol {
        font-size: medium;
    }

    table {
        font-size: smaller;
    }
}
</style>